.posts_wrapper {
  width: 100%;
  position: absolute;
}

.posts {
  margin: 6rem auto;
}

.breadcrumb {
  margin-bottom: 3.5rem;
}

.postsList {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.inactive {
  cursor: pointer;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: #7a7a7a;
}

.active {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: var(--text);
}

.post {
  border-radius: 18px;
  width: 100%;
  padding: 3.5rem;
  color: white;
  position: relative;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 18px;
  /* background: linear-gradient(247.15deg, #ff5c00 0%, #ff9950 103.74%); */
  -webkit-transition: transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.post:hover::after {
  transform: scale(1.05);
}

.card_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.title {
  font-size: 3rem;
}

.post::after {
  content: '';
  border-radius: 18px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.post:hover::after {
  opacity: 1;
}

.post h1 {
  font-size: 2rem;
}

.post h2 {
  font-size: 1.875rem;
}

.post h3 {
  font-size: 1.75rem;
}

.post_title {
  font-size: 2.5rem !important;
  margin-bottom: 1rem;
}

.post p, .post a {
  color: inherit;
  font-size: 1.5rem;
}

.post p {
  line-height: 1.2;
}

.post_excerpt {
  margin: 2rem 0;
}

.post_excerpt *+* {
  margin-top: .75rem;
}

.post_tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.post_tag {
  padding: 8px 16px;
  /* font-size: 1.5rem !important; */
  background-color: #1a141a;
  background: linear-gradient(247.15deg, #ff5c00 0%, #ff9950 103.74%);
  margin: 0.5rem 0;
  width: max-content;
  border-radius: 16px;
  text-transform: capitalize;
  margin-right: 1rem;
}

.post_details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.post_details>div {
  margin: 1rem 0;
}

.post_link {
  font-size: 1.75rem;
  color: white;
}